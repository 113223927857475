@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

:root {
  --main-blue: #3563f4;
  --main-blue-background: #EFF6FF;
  --light-gray: #f1f3f4;
  --light-green: #d3ffecff;
  --light-green-hover: #f0fff9ff;
  --sign-up-outline: #36498a;
  --dark-blue-button: #2a3041ff;
  --light-blue-button: #cbd1ecff;
  --dark-blue-button-hover: #5d6270ff;
  --light-blue-button-hover: #f3f5fcff;
  --main-blue-hover: #7b92f5ff;
  --delete-consultation-hover: #f4e8ffff;
  --main-red: #E84258;
  --light-red: #ffb8b8;
  --main-orange: #FD8060;
  --light-orange: #fcc092;
  --main-yellow: #FEE191;
  --light-yellow: #fcf992;
  --main-green: #a9fcaf;;
  --light-green: #B0D8A4;


  --pediatric: #4aa3ff;
  --therapy: #27da00;
  --radiology: #ecbb00;
  --emergency_response: #ff632f;
  --rehab: #ff2fa9;
  --sports_medicine: #ee1010;
  --physio: #01059e;
  --general_notes: #3563f4;
  --athletic: #ff8700;
  --pedorthist: #8dc700;
  --geriatric: #bf1581;
  --pdf_forms: #b3b3b3;
}


body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100%;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, ul, li, h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter'; /* Explicitly apply Questrial to text elements */
}